.layout {
  width: 100%;
  height: 100%;
  max-width: 650px;
  align-self: center;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  .container {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    overflow-y: auto;
  }
  nav {
    display: flex;
    justify-content: center;
    width: 100%;
    a {
      text-decoration: none;
      color: rgb(var(--background-color));
      text-transform: uppercase;
      font-weight: bold;
      div {
        cursor: pointer;
        background-color: rgba(var(--green-leaf), 1);
        border-radius: 0.5em;
        padding: 0.5em 1em;
        margin: 0.25em;
        transition: background-color 300ms;
      }
    }
    div.active {
      background-color: rgba(var(--green-darker), 1);
    }
    div:hover {
      background-color: rgba(var(--green-dark), 1);
    }
  }
}

.add {
  height: fit-content;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  h2 {
    margin: 2rem 0;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    min-height: fit-content;

    * {
      font-size: 1.3rem;
      padding: 0.5rem 1rem;
      margin: 0.5rem;
      border-radius: 0.5em;
      width: 100%;
    }
    h3 {
      font-size: 1.3rem;
      padding: 0;
      margin: 0;
      span {
        font-size: inherit;
        padding: 0;
        margin: 0;
      }
    }
    button {
      background-color: rgb(var(--green-leaf));
      width: fit-content;
    }
    textarea {
      height: 8em;
      min-height: 3.5em;
      width: 100%;
      resize: none;
    }
  }
}

.home {
  height: fit-content;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  .total {
    padding: 1em 0;
    h1 {
      margin-bottom: 0.5em;
    }
    div {
      display: flex;
      justify-content: center;
      div {
        margin: 1rem 0.35rem;
        background-color: rgb(var(--green-leaf));
        border-radius: 50%;
        height: 90px;
        width: 90px;
        display: flex;
        flex-direction: column;
        .amount {
          font-weight: 500;
          font-size: 1.2em;
        }
        .name {
          text-transform: uppercase;
          font-size: 0.8rem;
          font-weight: 100;
        }
      }
    }
  }
  .transactions {
    padding: 0.5em;
    padding-top: 0;
    display: flex;
    flex-direction: column;
  }
}

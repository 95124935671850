.profileblock {
  margin: 0.5rem 0;
  background-color: rgb(var(--green-leaf));
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  padding-right: 0;
  display: grid;
  grid-template-columns: 1fr auto;
  cursor: pointer;
  > * {
    display: flex;
    align-items: center;
  }
  h1 {
    text-align: left;
    font-size: 1.4rem;
    word-break: break-all;
    span {
      word-break: keep-all;
      padding: 0 0.5rem;
      text-align: right;
      font-weight: 100;
    }
  }

  p {
    padding: 0 1rem;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: 600;
  }
}
.profileblock:hover {
  animation: linear forwards 75ms pb-hover;
}

@keyframes pb-hover {
  from {
    background-color: rgb(var(--green-leaf));
  }
  to {
    background-color: rgb(var(--green-darker));
  }
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  border: none;
}

:root {
  --green-dark: 9, 51, 38;
  --green-light: 124, 186, 49;
  --green-input: 225, 235, 198;
  --green-lime: 204, 228, 47;
  --green-leaf: 136, 176, 75;
  --green-darker: 0, 114, 4;
  --background-color: 247, 250, 229;

  --red-ny: 221, 65, 36;
  --yellow-ny: 245, 223, 77;
  --white-ny: 239, 225, 206;
}

.leaf {
  color: rgba(var(--green-leaf), 1);
}

.popup-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .popup-container-bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(var(--background-color), 0.5);
    backdrop-filter: blur(0.3rem);
  }
  .popup-block {
    animation: popup 200ms backwards ease-in-out;
    position: absolute;
    background-color: rgb(var(--green-leaf));
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    height: fit-content;
    width: 90vw;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 1.4rem;
    }
    p {
      padding: 1rem 0;
      width: 100%;
    }
    button {
      cursor: pointer;
      border-radius: 0.5rem;
      background-color: rgba(var(--green-darker), 1);
      color: rgba(var(--background-color), 1);
      font-weight: bold;
      font-size: 0.9rem;
      padding: 0.4rem 0.8rem;
    }
    button:hover {
      background-color: rgb(var(--green-dark), 1);
    }
  }
}

@keyframes popup {
  0% {
    top: 0vh;
  }
  85% {
    top: 50vh;
  }
  100% {
    top: 45vh;
  }
}

.App {
  background-color: rgba(var(--background-color), 1);
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .name-div {
    h1 {
      font-size: 3em;
      font-weight: 800;
      span {
        color: rgba(var(--green-darker), 1);
      }
    }
    h2 {
      font-size: 1.7em;
      font-weight: 800;
      span {
        color: rgba(var(--green-leaf), 1);
      }
    }
  }
  .login-div form {
    label {
      color: rgba(var(--red-ny), 1);
    }
    input {
      font-size: 1.3em;
      padding: 0.5rem 1rem;
      margin: 0.5rem;
      border-radius: 0.5em;
      width: 100%;
    }
    .button-div {
      justify-content: center;
      display: flex;
      flex-direction: column;
      min-height: 4.5rem;
      button {
        border-radius: 1em;
        background-color: rgba(var(--green-leaf), 1);
        font-size: 1em;
        padding: 0.5em 1em;
        font-weight: 800;
        color: rgba(var(--background-color), 1);
      }
      button:hover {
        animation: ease-out button-hover 150ms forwards;
      }
    }
  }
}

.App > *,
.login-div form {
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@keyframes button-hover {
  to {
    padding: 1em 2.5em;
    font-size: 1.3em;
  }
}

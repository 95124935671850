.comments {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .comment {
    background-color: rgb(var(--green-input));
    margin: 0.3rem 0.5rem;
    text-align: left;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    .username {
      padding-right: 0.5rem;
      font-weight: bold;
    }
    > * {
      overflow: hidden;
      word-wrap: break-word;
      max-width: 100%;

      display: inline-block;
    }
  }
}

.new {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  padding-right: 0.5rem; //offset for the button
  .comment {
    padding: 6.5px 0.5rem;
    .content {
      flex: 1;
      font-size: 1rem;
    }
  }
  .buttons {
    background-color: transparent;
    border-radius: 0.5em;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    button {
      border-radius: 0.5em;
      padding: 0.1em;
      background-color: transparent;
      font-size: 1em;
      text-transform: uppercase;
      color: rgba(var(--green-darker));
      font-weight: bold;
      img {
        height: 35px;
        width: 35px;
      }
    }
    button:hover img {
      transform: scale(1.15);
    }
    button:last-child {
      padding: 0.3rem;
      img {
        height: 28px;
        width: 28px;
        transition: all 250ms;
      }
    }
  }
  > * {
    background-color: rgb(var(--green-input));
  }
}

.static {
  .data {
    padding: 0.5rem !important;
    .right {
      padding: 0 !important;
    }
  }
  .info {
    p {
      margin: 0 2rem;
    }
  }
}
.resized .static {
  .info {
    white-space: normal;
  }
}

.editing {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  min-height: fit-content;
  overflow: hidden;

  input[type="datetime-local"] {
    appearance: none;
    background-color: white;
    color: black;
    min-height: 2.8rem;
  }
  > * {
    min-height: fit-content;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    width: 100%;
  }
  h3 {
    font-size: 1.3rem;
    padding: 0;
    margin: 0;
    span {
      font-size: inherit;
      padding: 0;
      margin: 0;
    }
  }
  button {
    background-color: rgb(var(--green-leaf));
    width: fit-content;
  }
  textarea {
    height: 8em;
    min-height: 5em;
    width: 100%;
    resize: none;
  }
  .formbuttons {
    padding: 0;
    margin: 0.25rem 0;
    flex-direction: row;
    button {
      cursor: pointer;
      background-color: rgb(var(--green-darker));
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      margin: 0 0.25rem;
      color: rgba(var(--background-color), 1);
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1rem;
    }
    button:hover {
      transition: 300ms;
      background-color: rgba(var(--green-dark), 1);
    }
    button.red {
      background-color: #842715;
    }
    button.red:hover {
      background-color: #581a0e;
    }
    button.white {
      background-color: #d1b692;
    }
    button.white:hover {
      background-color: #867051;
    }
  }
}

.block {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-radius: 1rem;
  padding: 0.5rem 0;
  margin: 0.25rem 0;
  overflow: hidden;
  display: grid; // required for animation to work cross-platform, idk why
}

.basic {
  align-self: baseline;
  padding-left: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 50px;
  .data {
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    padding-right: 0;
    > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    overflow: hidden;
    .left {
      overflow: hidden;
      width: 100%;

      h1 {
        font-size: 1.3rem;
        span {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
          padding-right: 0.35rem;
        }
      }
      p {
        font-size: 0.8rem;
      }
    }
    .right {
      padding: 0 0.5em;
      border-radius: 0.5rem;
      p {
        font-size: 1.7rem;
      }
    }
  }
  .buttons {
    border-radius: 0.5em;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    button {
      border-radius: 0.5em;
      padding: 0.1em;
      background-color: transparent;
      font-size: 1em;
      text-transform: uppercase;
      color: rgba(var(--green-darker));
      font-weight: bold;
      img {
        height: 35px;
        width: 35px;
      }
    }
    button:hover {
      animation: linear 150ms forwards greenhover;
    }
    button.red:hover {
      animation: linear 150ms forwards redhover;
    }
    button.white:hover {
      animation: linear 150ms forwards whitehover;
    }
    button:last-child {
      padding: 0.3rem;
      img {
        height: 28px;
        width: 28px;
      }
    }
  }
  .info {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    p {
      overflow: hidden;
      margin-left: 2rem;
      text-overflow: ellipsis;
    }
  }
}

@keyframes greenhover {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(var(--green-darker), 1);
  }
}

@keyframes redhover {
  from {
    background-color: transparent;
  }
  to {
    background-color: #9c2d18;
  }
}

@keyframes whitehover {
  from {
    background-color: transparent;
  }
  to {
    background-color: #d1b692;
  }
}

.block {
  background-color: rgba(var(--green-leaf), 1);
}

.block.red {
  background-color: rgba(var(--red-ny), 1);
}

.block.white {
  background-color: rgba(var(--white-ny), 1);
}

.block.yellow {
  background-color: rgba(var(--yellow-ny), 1);
}

form.consumptionform {
  input {
    min-height: fit-content;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    width: 100%;
  }

  .input-and-swap {
    display: flex;
    flex-direction: row;

    padding: 0;
    margin: 0;

    input {
      margin-left: 0;
    }

    button {
      padding: 0;
      margin: 0;

      border-radius: 0.5em;
      padding: 0.1em;
      background-color: transparent;
      font-size: 1em;
      text-transform: uppercase;
      color: rgba(var(--green-darker));
      font-weight: bold;

      display: flex;
      align-items: center;
      img {
        margin: 0;
        padding: 0;

        height: 28px;
        width: 28px;
        transition: all 250ms;
      }
    }
    button:hover img {
      transform: scale(1.15);
    }
  }
}

.social {
  padding: 1rem 0.5rem;
  display: grid;
  .nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    > * {
      cursor: pointer;
      text-transform: uppercase;
      color: rgb(var(--background-color));
      font-weight: bold;
      padding: 0.5rem 1rem;
      background-color: rgb(var(--green-leaf));
      border-radius: 0.5rem;
      margin: 0.25rem;
      transition: background-color 300ms;
    }
    .selected {
      background-color: rgb(var(--green-darker));
    }
    > *:hover {
      background-color: rgb(var(--green-dark));
    }
  }
}

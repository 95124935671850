.profilepage {
  .head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .back {
      cursor: pointer;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0 1rem;
      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem;
      transition: 300ms background-color;
    }
    .back:hover {
      background-color: rgba(var(--green-leaf), 1);
    }
    h1::before {
      content: "Now viewing the profile of our fellow kratomista";
      font-size: 1rem;
      font-family: "Pacifico", cursive;
      font-weight: normal;
      display: block;
      color: rgba(0, 0, 0, 0.69);
    }
    h1 {
      font-size: 2.5rem;
      color: rgba(var(--green-darker), 1);
      padding: 1.5rem 0.5rem 2rem 0.5rem;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    .nav {
      display: flex;
      flex-direction: row;
      > * {
        cursor: pointer;
        text-transform: uppercase;
        color: rgb(var(--background-color));
        font-weight: bold;
        padding: 0.5rem 1rem;
        background-color: rgb(var(--green-leaf));
        border-radius: 0.5rem;
        margin: 0.25rem;
        transition: background-color 300ms;
      }
      .selected {
        background-color: rgb(var(--green-darker));
      }
      > *:hover {
        background-color: rgb(var(--green-dark));
      }
    }
  }
  .transactions {
    display: flex;
    flex-direction: column;
  }
  .statistics {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .group {
      width: 100%;
      text-align: left;
      border-radius: 1rem;
      background-color: rgba(var(--green-leaf), 0.2);
      padding: 0.5rem 1rem;
      margin: 0.5rem 0;
      h2 {
        text-align: center;
        font-size: 1.3rem;
      }
      .stat {
        margin-bottom: 0.25rem;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid rgba(var(--green-darker), 1);
        .name {
          flex: 1;
          padding-right: 0.5rem;
          word-break: break-word;
          max-height: 7rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .value {
          align-self: center;
        }
      }
    }
  }
}

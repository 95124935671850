.image-preview {
  position: absolute;
  top: 0;
  left: 0;

  grid-template-rows: 1fr auto;
  justify-content: center;
  background-color: rgba(var(--background-color), 0.85);
  backdrop-filter: blur(2px);

  visibility: none;
  display: none;

  height: 100vh;
  width: 100vw;

  #img-container {
    justify-self: center;
    align-self: center;
    max-width: min(600px, 90vw);
    img {
      border-radius: 0.5rem;
      display: block;
      min-width: 2.5rem;
      min-height: 2.5rem;
      max-width: 100%;
      max-height: 70vh;
      object-fit: contain;
    }
  }

  .buttons {
    padding: 0 1rem;
    padding-top: 0.5rem;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    button {
      justify-self: center;
      width: fit-content;
      background-color: transparent;
      padding: 0.5em 1em;
      img {
        width: 30px;
        height: 30px;
        transition: all 250ms;
      }
    }
    button:hover img {
      transform: scale(1.3);
    }
  }
}

.chat::before {
  padding-top: 0.5rem;
  content: "Stay in touch with the rest of kratomisti";
  font-size: 1rem;
  font-family: "Pacifico", cursive;
  font-weight: normal;
  display: block;
  color: rgba(0, 0, 0, 0.69);
}

.chat {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  gap: 0.5rem;

  .message-block {
    overflow: hidden;
    max-width: min(90%, 600px); // will never reach 600px
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;

    .header .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      .timestamp {
        font-size: 0.8rem;
        color: rgb(var(--green-darker));
      }
    }

    .body {
      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem;
      text-align: left;
      background-color: rgba(var(--green-leaf), 0.85);

      max-width: 100%;
      width: fit-content;
      overflow: hidden;

      .author {
        color: rgb(var(--green-dark));
        font-size: 0.9rem;
      }
      .content {
        font-size: 0.9rem;
        .text {
          word-wrap: break-word;
        }
      }
    }
    .body.text.emoji {
      background-color: transparent;
      padding: 0;
      padding-top: 0.25rem;
      .author {
        color: rgb(var(--green-darker));
        line-height: 0.9rem;
      }
      .content {
        font-size: 1.5rem;
      }
    }

    .body.image {
      padding: 0;
      background-color: transparent;

      .author {
        padding-left: 0.5rem;
        color: rgb(var(--green-darker));
      }
      .content {
        max-width: min(400px, 70vw);
        img {
          border-radius: 0.5rem;
          display: block;
          min-width: 2.5rem;
          min-height: 2.5rem;
          max-width: 100%;
          max-height: 300px;
          object-fit: contain;
        }
      }
    }
  }

  .message-block.self {
    align-self: flex-end;
    grid-template-columns: 1fr auto;
    width: 100%;
    .header .wrapper {
      justify-content: right;
    }
    .body {
      .author {
        display: none;
      }
      background-color: rgb(var(--green-input));
    }
  }

  .input {
    background-color: rgb(var(--background-color));
    position: sticky;
    top: 0;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    gap: 0.5rem;

    .message {
      display: flex;
      border-radius: 0.5rem;
      margin: 0.25rem 0;
      --padding: 1rem;
      padding: calc(var(--padding) / 2) var(--padding);

      .content {
        line-height: 19.2px;
        flex: 1;
        font-size: 1rem;
        background-color: transparent;
        height: 20px;
        overflow-y: auto;
        resize: none;
        outline: none;
      }
    }

    .button-option {
      background-color: transparent;
      img {
        height: 28px;
        transition: all 250ms;
      }
    }
    .button-option:hover img {
      transform: scale(1.15);
    }

    .buttons {
      background-color: transparent;
      border-radius: 0.5em;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;

      > * {
        border-radius: 0.5rem;
        padding: 0.1rem;
        background-color: transparent;
        font-size: 1rem;
        text-transform: uppercase;
        color: rgba(var(--green-darker));
        font-weight: bold;
        img {
          height: 28px;
          width: 28px;
          transition: all 250ms;
        }
      }
      > *:hover img {
        transform: scale(1.15);
      }
    }
    > * {
      background-color: rgb(var(--green-input));
    }
  }
}
